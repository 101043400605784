<template>
  <window-content v-if="!loading && !errors && !success">
    <window-header>
      <div class="wrapper-sm">
        <p class="font-14 font-bold no-margin" style="color: #293e52">
          Importação registrada {{importacao.createdAt.date|formatDate}} e atualmente encontra-se com o status <strong
            :class="Status.Status[importacao.status].class">{{Status.Status[importacao.status].title}}</strong>
        </p>
        <div class="m-t-xs font-13 text-grey-7" style="color: #748396; padding-right: 20px; line-height: 1">
          Tipo de importação: <span class="uppercase">{{importacao.type}}</span>
          <div v-if="importacao.status === 1">
            <u-spinner-hourglass />
          </div>
        </div>
      </div>
    </window-header>
    <window-body>
      <div class="erp-list lista-migracao-items w-full">
        <u-table
            ref="table"
            color="primary"
            :data="items"
            :columns="table.columns"
            :filter="table.busca"
            row-key="id"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            @request="request"
            :pagination.sync="table.serverPagination"
            :rows-per-page-options="[20, 50, 100]"
            class="erp-table w-full"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <!--<u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>-->
            <u-th :style="col.name === 'id' ? ' max-width: 45px' : ''" v-for="(col, index) in props.cols" :key="col.name || 'col-' + index"
                  :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr class="cursor-pointer" slot="body" slot-scope="props"
                :props="props">
            <!--<u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs" />
            </u-td>-->
            <u-td key="_status" :props="props">
              <div v-if="!props.row.type || props.row.type !== 'rel'"
                   :class="ItemStatus.Status[props.row.status].class">
                {{ItemStatus.Status[props.row.status].title}}
                <span v-if="props.row.status === ItemStatus.STATUS_FALHA">
                  <u-icon name="question-circle" type="fa" @click.native="props.row._dialog = props.row._dialog"></u-icon>
                </span>
              </div>
              <span v-else></span>
            </u-td>
            <u-td v-for="(header, index) in importacao.header" :key="header || index" :props="props">
              <div v-if="!props.row.type || props.row.type !== 'rel'">
                {{getProp(props, header, index)}}
              </div>
              <div v-else>
                <select v-model="parseField[header]" v-if="!(['id'].includes(header))">
                  <option :value="null">-</option>
                  <option :value="f" :selected="checkTips(item, header)" v-for="(item, f) in fieldMap">{{item.label}}
                  </option>
                </select>
              </div>
              <span class="text-negative" style="white-space: break-spaces" v-if="index === 0 && props.row.status === ItemStatus.STATUS_FALHA">{{props.row.descricao}}</span>
            </u-td>
            <u-td class="text-center" key="_opts" :props="props">

            </u-td>
          </u-tr>
        </u-table>
      </div>
    </window-body>
    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <!--<u-btn v-if="Number(importacao.status) === Status.STATUS_ANALISE" type="button" icon="save"
               icon-color="grey" icon-type="fa" icon-style="light" label="Salvar configurações" color="grey-6"
               flat no-caps
        />-->

        <u-btn type="button" icon="check"
               icon-color="positive" icon-type="fa" icon-style="light" label="Aprovar e iniciar migração"
               @click="iniciarMigracao" color="grey-6"
               flat no-caps
        /> <!-- v-if="Number(importacao.status) === Status.STATUS_ANALISE" -->
        <!--<u-btn v-if="Number(importacao.status) === Status.STATUS_ANALISE" type="button" icon="cog"
               icon-color="grey" icon-type="fa" icon-style="light" label="Config/Conversores" color="grey-6"
               flat no-caps
        />-->
        <u-btn
            v-if="Number(importacao.status) === Status.STATUS_ANALISE || Number(importacao.status) === Status.STATUS_EM_PROCESSAMENTO"
            :disable="loading" type="button" icon="ban" icon-color="negative" icon-type="fa" icon-style="light"
            label="Cancelar migração" color="negative" flat no-caps @click="cancelarMigracao" :loading="loadingCancel"
        />
        <u-btn :disable="loading" type="button" icon="sync" icon-type="fa" icon-style="light" label="Atualizar"
               flat no-caps
               @click="load" />
        <u-btn :disable="loading" type="button" icon="times" icon-type="fa" icon-style="light" label="Fechar"
               color="grey-6" flat no-caps
               @click="$uloc.window.close($root.wid)" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Importação iniciada com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
    div
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {find, cancel, iniciar} from '@/domain/importador/services'
import {date, USpinnerHourglass, UTooltip} from 'uloc-vue'
import * as Status from '../../../../domain/importador/helpers/Status'
import * as ItemStatus from '../../../../domain/importador/helpers/ItemStatus'
import {UTable, UTh, UTr, UTd /*UCheckbox*/} from 'uloc-vue'
import {WindowContent, WindowHeader, WindowBody, WindowFooter} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import WindowSuccess from '../../../layout/window/Success'

export default {
  name: 'Importacao',
  props: ['id'],
  components: {
    USpinnerHourglass,
    UTable,
    UTh,
    UTr,
    UTd,
    WindowContent,
    WindowHeader,
    WindowBody,
    WindowFooter,
    WindowLoading,
    WindowSuccess,
    // UTooltip,
    /*UCheckbox*/
  },
  mounted () {
    this.load(this.id)
  },
  watch: {
    'parseField' (v) {
      console.log(v)
    }
  },
  computed: {
    Status () {
      return Status
    },
    ItemStatus () {
      return ItemStatus
    }
  },
  data () {
    return {
      loading: !!this.id,
      loadingCancel: false,
      success: null,
      errors: null,
      importacao: null,
      items: null,
      status: null,
      fieldMap: null,
      parseField: [],
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 1, // specifying this determines pagination is server-side
          rowsPerPage: 100
        },
        columns: [
          {name: 'id', label: 'ID', field: 'id', align: 'left', sortable: false}
        ],
        filter: '',
        selected: [],
        loading: false
      }
    }
  },
  methods: {
    request ({pagination, filter}) {

      let extraFilters = []

      // this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)

      /*if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }*/

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.descending}&search=${filter}${extraFilters.join('')}`)

      find(this.id, pagination.rowsPerPage, pagination.page, filtros)
        .then(({data}) => {
          this.table.serverPagination = pagination
          this.table.serverPagination.rowsNumber = data.items.total
          this.importacao = data.migration
          this.parseField = data.migration.parseField || [] // TODO: Evoluir
          console.log(this.parseField)
          this.fieldMap = data.fieldMap
          let result = data.items.result
          // result.unshift({type: 'rel'}) // TODO: Evoluir
          this.items = result
          this.table.columns = data.migration.header.slice().map(item => {
            return {
              name: item, // TODO: Remover caracteres especiais
              label: item,
              field: item,
              align: 'left',
              sortable: false
            }
          })
          this.table.columns.unshift({
            name: '_status', // TODO: Remover caracteres especiais
            label: 'Migração',
            field: '_status',
            align: 'left',
            sortable: false
          })
          this.table.columns.push({
            name: '_opts',
            label: 'Opts',
            field: '_opts',
            align: 'left',
            sortable: false
          })
          this.table.loading = false
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.alertApiError(error)
          this.loading = false
          this.table.loading = false
        })
    },
    load () {
      this.loading = true
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    checkTips (item, name) {
      if (!item.tips || !Array.isArray(item.tips)) {
        return false
      }
      return item.tips.map(i => i.toLowerCase()).includes(String(name).toLowerCase())
    },
    cancelarMigracao () {
      this.confirmDialog('Tem certeza que deseja CANCELAR esta migração?', '', () => {
          this.loadingCancel = true
          cancel(this.id)
            .then(({data}) => {
              this.importacao = data
              this.loadingCancel = false
              this.$uloc.notify({color: 'success', message: `Migração cancelada com sucesso.`})
              this.$uloc.window.emit(this.$root.wid, 'created')
            })
            .catch(error => {
              this.alertApiError(error)
              this.loadingCancel = false
            })
        }
      )
    },
    iniciarMigracao () {
      this.confirmDialog('Tem certeza que deseja iniciar esta migração?', '', () => {
          // this.loadingCancel = true
          iniciar(this.id)
            .then(({data}) => {
              // this.importacao = data
              // this.loadingCancel = false
              this.$uloc.notify({color: 'success', message: `Migração iniciada com sucesso. `})
              this.$uloc.window.emit(this.$root.wid, 'created')
            })
            .catch(error => {
              this.alertApiError(error)
              // this.loadingCancel = false
            })
        }
      )
    },
    confirmDialog (title, message, callback) {
      this.$uloc.dialog({
        title,
        message,
        cancel: true,
        color: 'negative'
      })
        .then(() => {
          this.$uloc.notify({color: 'warning', message: `Processando, aguarde...`})
          callback()
        })
        .catch(() => {
        })
    },
    getProp (props, header, index) {
      if (typeof props.row.conteudo[index] !== 'undefined') {
        return props.row.conteudo[index]
      }
      if (typeof props.row.conteudo[header] !== 'undefined') {
        return props.row.conteudo[header]
      }
      return '?'
    }
  }
}
</script>

<style lang="stylus">
  .lista-migracao-items {
    .scroll {
      // overflow unset
      max-height 70vh
    }
  }
</style>
